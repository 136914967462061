import axios from 'axios'

const syzojService = axios.create({
  baseURL: 'https://api.algorithms2019.com/algorithm/syzoj',
  timeout: 10000
})

syzojService.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

syzojService.interceptors.response.use(res => {
  return res
}, err => {
  return Promise.reject(err)
})

export default syzojService
