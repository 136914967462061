<template>
  <div class="wrapper">
    <div class="submission-container">
      <el-table
        :data="resultList"
        style="width: 100%"
      >
        <el-table-column
          prop="submissionId"
          label="编号"
        />
        <el-table-column
          prop="problemName"
          label="题目"
        />

        <el-table-column
          label="状态"
          prop="result"
        >
          <template v-slot="{row}">
            <div v-if="row.result === 'Accepted'" class="accepted"> {{ row.result }} </div>
            <div v-else-if="row.result === 'Pending'" class="info"> {{ row.result }}  <i class="el-icon-loading" /></div>
            <div v-else class="wrong"> {{ row.result }} </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="score"
          label="分数"
        />
        <el-table-column
          prop="time"
          label="总时间"
        >
          <template v-slot="{row}">
            {{ row.time && `${row.time}ms` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="memory"
          label="内存"
        >
          <template v-slot="{row}">
            {{ row.memory && `${row.memory}K` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="language"
          label="代码"
        />
        <!--        <el-table-column-->
        <!--          prop="address"-->
        <!--          label="提交者"-->
        <!--        />-->
        <el-table-column
          prop="submitTime"
          label="提交时间"
        />
      </el-table>

      <el-card class="code-container" style="white-space: pre-wrap;" v-html="submissionResult.code">
        {{ submissionResult.code }}
      </el-card>

      <div v-if="judgeResult && judgeResult.compile.status === 2" class="test-table">
        <el-collapse accordion>
          <el-collapse-item v-for="(item,index) in testList" :key="index">
            <template slot="title">
              <div class="collapse-title">
                <div>测试点{{ index + 1 }}</div>
                <!--                <div>-->
                <!--                  <div v-if="(item.result.userOutput).concat('\n\r') === item.result.output.content" style="color: #67C23A ">-->
                <!--                    Accepted-->
                <!--                  </div>-->

                <!--                  <div v-else style=" color: #F56C6C;">-->
                <!--                    Wrong Answer-->
                <!--                  </div>-->
                <!--                </div>-->
                <div>分数: {{ Math.trunc(item.result.scoringRate *100) }}</div>
                <div>用时: {{ item.result.time }} ms</div>
                <div>内存: {{ item.result.memory }} kb</div>
              </div>
            </template>
            <div class="test-info">
              <div class="label">输入文件 ({{ item.result.input.name }})</div>
              <div class="value"> {{ item.result.input.content }}</div>

              <div class="label">答案文件 ({{ item.result.output.name }})</div>
              <div class="value"> {{ item.result.output.content }}</div>

              <div class="label">用户输出 </div>
              <div class="value"> {{ item.result.userOutput }}</div>

              <div class="label">系统信息 </div>
              <div class="value"> {{ item.result.systemMessage }}</div>

            </div>

          </el-collapse-item>

        </el-collapse>
      </div>

      <div v-show="judgeResult && judgeResult.compile.status !== 2" class="test-table">
        <el-card>
          <div slot="header" class="clearfix">
            <span>编译信息</span>
          </div>
          <div class="code-style" v-html="judgeResult && judgeResult.compile.message">{{ judgeResult && judgeResult.compile.message }}</div>
        </el-card>
      </div>
    </div>

  </div>
</template>

<script>
import { getSubmission } from '@/api/syzoj'

export default {
  name: 'Judge',
  data() {
    return {
      submissionResult: {},
      resultList: [],
      testList: [],
      listLoading: null,
      list: [],
      judgeResult: undefined
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      while (!this.judgeResult) {
        const submission = await getSubmission(this.$route.query.sid).then(res => res.data.data)
        console.log(submission)
        this.submissionResult = submission

        let data = submission.roughResult || {
          result: 'Pending'
        }

        if (submission.info) {
          /* data.submissionId = submission.info.submissionId
          data.problemName = submission.info.problemName
          data.submitTime = submission.info.submitTime
          data.language = submission.info.language */
          data = {
            ...data,
            ...submission.info
          }
        }

        this.resultList = [data]

        const detailResult = submission.detailResult
        if (detailResult?.compile?.status === 2) {
          this.testList = detailResult.judge.subtasks[0].cases
          this.judgeResult = detailResult
        }

        await new Promise(resolve => setTimeout(resolve, 1500))
      }
    }
  }
}
</script>

<style  scoped>

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 50px 130px;
  margin-top: 50px;
}

.submission-container {
  width: 100%;
  height: fit-content;
  margin-bottom: 50px;
}

.accepted {
  color: #67C23A;
  font-weight: bolder;
}

.wrong {
  color: #F56C6C;
  font-weight: bolder;
}

.info {
  color: #2579e7;
  font-weight: bolder;
}
.test-table {
  margin-top: 60px;
}

.code-container{
  border: 1px #E4E7ED solid;
  //border-radius: 10px;
  padding:20px;
  margin: 20px 0;
}

.collapse-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  color: #5a5e66;
}

.test-info{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 3px;

}

.label {
  font-weight: bolder;
}

.value{
  border: 1px #E4E7ED solid;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

.code-style{
  white-space: pre-wrap;
  line-height: 26px;
}

</style>
