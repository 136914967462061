import syzojRequest from '@/utils/syzojRequest'

// 提交题目
export function submitCode(data) {
  return syzojRequest({
    url: '/submit',
    method: 'post',
    data
  })
}

export function getSubmission(sid) {
  return syzojRequest({
    url: `/submission/${sid}`,
    method: 'get'
  })
}

// 在线题库-列表详情-题目答案
export function questionAnswerList(data) {
  return syzojRequest({
    url: `/code/${data}`,
    method: 'get',
    data
  })
}
